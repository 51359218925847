import { AuthService } from 'src/app/_services/auth.service';
import { Component } from '@angular/core';
import { AlertController, MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Geolocation } from '@capacitor/geolocation';
import { OpenReminderPage } from './pages/patient/notifications/open-reminder/open-reminder.page';
import { BehaviorSubject } from 'rxjs';
import { App } from '@capacitor/app';
import { repeat } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/messaging';

interface User {
  userId: string;
  name: String;
  email: String;
  userType: Number;
  ownerType: String;
  token: String;
  profileImageUrl: string;
}
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  selectedMenu: any;
  private authUserSub: BehaviorSubject<User>;
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    public menuController: MenuController,
    private router: Router,
    private authService: AuthService,
    public toastController: ToastController,
    private modalController: ModalController,
    public afMessaging: AngularFireMessaging,
    private alertController : AlertController
  ) {
    this.initializeApp();
    this.listen();
    let userType = localStorage.getItem('authUser');
    
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      // if (!this.platform.is('mobile')) {
      //   this.authService.tokenGenerate();
      // }
      // else {
      //   console.log("Token not generating for web ");
      // }

      App.addListener('appStateChange', ({ isActive }) => {
        
        if (isActive && this.authService.getFCMToken() != null && this.authService.isLoggedIn == true) {
          // alert("i am inside login calling function")
          this.authService.validateLoginAPI();
          // this.authService.validateLogin().
          //   subscribe(resp => {
          //     console.log("Response ", resp);
          //     if (resp.errorcode == 0 && resp.resultObj.validUser != true) {
          //       // localStorage.removeItem('authUser');
          //       // this.authUserSub.next(null);
          //       // this.router.navigate(['/login']);
          //       this.authService.logout(true);
          //     }
          //   });
        }
      });
      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 1000
        });
      }, 2000)

      // await SplashScreen.hide();
      // Display content under transparent status bar (Android only)  
      //if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        StatusBar.setOverlaysWebView({ overlay: true });
      }

      this.translate.setDefaultLang('en');

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.setDefaultLang(event.lang);
      });

      const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

      if (isPushNotificationsAvailable) {
        this.initializeFirebase();
      }
    });
  }

  async openModal(notificationObject) {
    const { notification, data  } = notificationObject;
    
    const modal = await this.modalController.create({
      component: OpenReminderPage,
      cssClass: 'custom-modal',
      componentProps: {
        'Title': notification.data.title,
        'Message': notification.data.message,
        'Id': data.notification_type_id
      }
     
    });
    return await modal.present();
  }

  async presentToast(notification: any) {
    if (!notification?.message) {
      const toast = await this.toastController.create({
        message: notification.message,
        header: notification.title,
        duration: 3000,
        position: "top"
      });
      toast.present();
    }
  }

  initializeFirebase() {

    console.log('Initializing Firebase');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      this.authService.setFCMToken(token.value);
      this.authService.validateLogin();
      //alert('Push registration success, token: ' + token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notificationObject: PushNotificationSchema) => {
        const { data } = notificationObject;
        if (data.notification_type == "reminder" && Capacitor.getPlatform() === 'android') {         
          //await this.openModal(notificationObject);
          await this.authService.openModal(notificationObject.data);

        }else {
          if (Capacitor.getPlatform() === 'android') {
            await this.presentToast(notificationObject);
          }
        }
        if (data.notification_type == "logout") {
          this.authService.logout(true);
        }

        // console.log(`User opened a notification ${JSON.stringify(data.notification_type)}`)
        // // this.router.navigate(['/patient/reward-history']);
        // if (data.notification_type == "appointment_requested" || data.notification_type == "appointment_approved"
        //  || data.notification_type == "appointment_cancelled" || data.notification_type == "appointment_reschedule") {
        //   this.router.navigate(['/patient/appointments']);
        // }
        // else if (data.notification_type == "reward_redeemed" || data.notification_type == "reward_added") {
        //   this.router.navigate(['/patient/reward-history']);
        // }
        // else if (data.notification_type == "basic_subscription") {
        //   this.router.navigate(['/patient/my-membership']);
        // }
      },
    );

    
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        const { data } = notification.notification;
        console.log(`User opened a notification ${JSON.stringify(data.notification_type)}`)
        // this.router.navigate(['/patient/reward-history']);
        if (data.notification_type == "appointment_requested" || data.notification_type == "appointment_approved"
          || data.notification_type == "appointment_cancelled" || data.notification_type == "appointment_reschedule") {

          this.router.navigate(['/patient/appointments']);
        }
        else if (data.notification_type == "reward_redeemed" || data.notification_type == "reward_added") {
          this.router.navigate(['/patient/reward-history']);
        }
        else if (data.notification_type == "basic_subscription") {
          this.router.navigate(['/patient/my-membership']);
        }
        // else if (data.notification_type == "logout") {
        //   this.authService.logout(true);
        // }
        else if (data.notification_type == "reminder") {
          console.log("Notificatrion object", notification );
          await this.openModal(notification);
        }
      },
    );
  }

  
  listen() {
    const me = this;
    me.afMessaging.messages
      .subscribe((message) => {
        const { title, body } = message["notification"];
        const { notification_type, notification_type_id} = message["data"];
        
        me.handleNotification(title, body, notification_type, notification_type_id)
      });
  }

  handleNotification(title, body, notification_type, notification_type_id){
    let checkNotification = "Notification";
     if(notification_type == 'reminder'){
       checkNotification = "Reminder";
    }
    else {
       console.log("CheckNotification", checkNotification);

    }
   this.alertController.create({

     header: checkNotification,
     subHeader: title,
     message: body,
     buttons: [
       'Cancel',
       {
         text: 'ok',
         handler: () => {
           let userType = localStorage.getItem('authUser');
           let preurl = "patient";

           if (JSON.parse(userType).userType == 2 ){
            preurl = "provider";             
           }
           if (JSON.parse(userType).userType == 4 ){
            preurl = "physician";             
           }
           if (JSON.parse(userType).userType == 5 ){
            preurl = "site-admin";             
           }
           if (notification_type == "appointment_requested" || notification_type == "appointment_approved"
             || notification_type == "appointment_cancelled" || notification_type == "appointment_reschedule") {

             this.router.navigate(['/'+ preurl +'/appointments']);
           }
           else if (notification_type == "reward_redeemed" || notification_type == "reward_added") {
             this.router.navigate(['/' + preurl +'/reward-history']);
           }
           else if (notification_type == "basic_subscription") {
             this.router.navigate(['/' + preurl +'/my-membership']);
           }
           // else if (notification_type == "logout") {
           //   this.authService.logout(true);
           // }
           else if (notification_type == "reminder") {
             this.router.navigate(['/' + preurl +'/reminder-detail', notification_type_id]);
           }
         }
       }
     ]
   }).then(res => {
     res.present();
   });
  }

}
