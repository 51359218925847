import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    private _baseUrl = environment.API_URL;
    private _headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private _http: HttpClient) { }

    public getAllCategory = () => {
        let apiEndPoint = '/category/getall';
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public getsubcategorybycategoryid = (categoryId) => {
        let apiEndPoint = '/category/getsubcategorybycategoryid?categoryId=' + categoryId;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public getpatientplan = () => {
        let apiEndPoint = '/membershipplan/getpatientplan';
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public getoffersbycategory = (searchQuery) => {
        let apiEndPoint = '/category/getoffersbycategory' + searchQuery;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public getofferdetail = (offer_key, planId, category_key) => {
        let apiEndPoint = '/category/getofferdetail?planId=' + planId + '&category_key=' + category_key + '&offer_key=' + offer_key;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public getplansubcategories = (planId, category_key) => {
        let apiEndPoint = '/membershipplan/getplansubcategories?planId=' + planId + '&category_key=' + category_key;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public redeemreward = (payload) => {
        let apiEndPoint = '/rewards/redeemreward';
        return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
    }

    public getRewardByOfferKey = (offer_key) => {
        let apiEndPoint = '/rewards/getrewardbyoffer?offer_key=' + offer_key;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public redeemCoupon = (redemption_type, offer_key) => {
        let apiEndPoint = '/category/redeemcoupon?redemption_type=' + redemption_type + '&offer_key=' + offer_key;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public gettotalrewardpoint = (patientId) => {
        let apiEndPoint = '/rewards/gettotalrewardpoint?patientId=' + patientId;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public getfeedback = (rewardId) => {
        let apiEndPoint = '/feedback/getfeedback?rewardId=' + rewardId;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

    public addFeedback = (payload) => {
        let apiEndPoint = '/feedback/create';
        return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
    }

    public addAppointmentFeedback = (payload) => {
        let apiEndPoint = '/appointment/updatePatientFeedback';
        return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
    }

    public getLocationsWithOfferKey = (searchQuery) => {
        let apiEndPoint = `/category/getLocationsWithOfferKey?${searchQuery}`;
        return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
    }

}
