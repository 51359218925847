// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  WEB_URL:'https://docshow.ukitss.com/',
  API_URL: 'https://docshow-api.ukitss.com/api', //Local URL


  //WEB_URL: 'https://localhost:4200/', // Local URL
  //API_URL: 'http://localhost:3009/api', //Local URL
  
  stripe_key: 'pk_test_51IJrxgKWtiNi7bZvn2xqrTZUxz6B4Gep0H6fga7AzXiYs9SblXD1NCBJsNnbp86TSHPHIFiiTS3C7VRkFztqaZzj00o4mTGM3G',
  // WEB_URL: 'https://docshow.ukitss.com',
  PUBLIC_VAPID_KEY: "BIwEbG10TyXM7dXiRuS7VGR3tOsith4jxGzp9U0pcGl5oh_Vk2FT9JmXhZPRaI9aIFagQxXNs9-qNfbyiL_EwkQ"
};


/*
 * For easier de
bugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
