import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'appointment-status',
  templateUrl: './appointment-status.component.html',
  styleUrls: ['./appointment-status.component.scss']
})
export class AppointmentStatusComponent implements OnInit , OnChanges{

  @Input() status: string;

  formatColor: string;
  formatText: string;

  ngOnInit() {
  }
  
  ngOnChanges(){
    this.formatStatus();
  }

  formatStatus() {
    let _status;
    let _color;
    switch (this.status) {
      case 'pending':
        _status = 'Pending';
        _color = 'success';
        break;
      case 'reschedule':
        _status = 'Re-Schedule';
        _color = 'success';
        break;
      case 'approved':
        _status = "Approved";
        _color = 'primary';
        break;
      case 'arrived':
        _status = 'Arrived';
        _color = 'secondary';
        break;
      case 'visited':
        _status = 'Visit Completed';
        _color = 'secondary';
        break;
      case 'unattended':
        _status = 'Unattended';
        _color = 'success';
        break;
      case 'noshow':
        _status = 'No-Show';
        _color = 'danger';
        break;
      case 'cancel':
        _status = 'Cancelled';
        _color = 'danger';
        break;
      case 'unapproved':
        _status = 'Unapproved';
        _color = 'danger';
        break;
      default:
        _status = 'Uknown';
        _color = 'medium';
        break;
    }
    this.formatText= _status;
    this.formatColor=_color;
  }
}
