import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { addMinutes, eachMinuteOfInterval, endOfDay, format, isEqual, isFuture, isSameDay, startOfDay } from 'date-fns';

@Component({
  templateUrl: './provider-availability.page.html',
  styleUrls: ['./provider-availability.page.scss']
})
export class ProviderAvailabilityPage implements OnInit {

  @Input() doctorId: any;
  @Input() currentSlot: any;

  calendarDate:any;
  currentDate: any;
  timeSlots: any[];
  weeklySchedule: any[];

  constructor(private modalController: ModalController, private loading: LoadingService, private profileService: ProfileService, private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.calendarDate=format(new Date(),'yyyy-MM-dd');
    this.getWeeklySchedule();    
    
    // setTimeout(() => {
    //   this.changeDate(this.calendarDate);
    // }, 100);
  }

  async changeDate($event) {
    await this.loading.present()

    let d = new Date($event);
    this.currentDate = d;

    const days = ['Sunday', 'Monday', 'Tuseday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let dayName = days[d.getDay()];
    if (this.weeklySchedule && this.weeklySchedule.length > 0) {
      let daySchedule = this.weeklySchedule.find(m => m.day == dayName);

      if (daySchedule) {
        let sd = new Date(daySchedule.startDateTime);
        let ed = new Date(daySchedule.endDateTime);
        
        if (!isSameDay(sd, ed)) {
          sd = startOfDay(d);
          ed = endOfDay(d);
        }

        let startTime = new Date(d.getFullYear(), d.getMonth(), d.getDate(), sd.getHours(), sd.getMinutes());
        let endTime = new Date(d.getFullYear(), d.getMonth(), d.getDate(), ed.getHours(), ed.getMinutes());

        const slots = eachMinuteOfInterval({
          start: startTime,
          end: endTime
        }, { step: 30 })

        this.timeSlots = [];
        slots.forEach(date => {
          this.timeSlots.push({ startTime: date, endTime: addMinutes(date, 30), isDisabled: !isFuture(date)})
        });

        //update availability and appointment
        this.updateSlots();

      } else {
        this.timeSlots = [];
      }
    }
    await this.loading.dismiss();
  }


  getWeeklySchedule() {
    const userId = this.doctorId;

    this.profileService.getUserProfile(userId)
      .subscribe(resp => {
        if (resp.errorcode == 0) {
          if (resp.resultObj.weeklySchedule) {
            this.weeklySchedule = JSON.parse(resp.resultObj.weeklySchedule);
            this.changeDate(this.calendarDate);
          }
        }
      });
  }

  updateSlots() {
    if (!(this.currentDate)) {
      return;
    }

    let getSlotsPayload = {
      userId: this.doctorId,
      date: new Date(this.currentDate.getTime() - (this.currentDate.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0]
    }

    this.appointmentService.getSlotsNew(getSlotsPayload).subscribe(response => {
      if (response.resultObj) {
        response.resultObj.map(value => {
          let scItem = this.timeSlots.find(m => {
            let slotTime = new Date(value.startTime);
            let scTime = new Date(m.startTime);
            return isEqual(scTime, slotTime)
          });

          if (scItem) {          
              scItem['slotId'] = value.id;
              scItem['status'] = value.status;            
          }      
        });    
      }
    });
  }

  onSelectSlot(item) {
    const data = {
      slot: item
    }
    
    this.modalController.dismiss(data);
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
