import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertController } from '@ionic/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router, private alertController: AlertController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
           
            if (err.status === 403 || err.status === 401) {
                    this.alertController.create({
                      header: err.statusText,
                      message: err.error.errorMsg,
                      buttons: ['OK']
                    }).then(res => {
                      res.present();
                    });
                  
                // auto logout if 401 response returned from api
                // this.authService.logout();
                // location.reload();
                localStorage.removeItem('authUser');
                this.router.navigate(['/login']);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}