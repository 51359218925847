import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportPage implements OnInit {

  constructor(private router : Router ) { }

  ngOnInit() {}
  gotohome() {
    this.router.navigate(['/patient']);
  }

}
