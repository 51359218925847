import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './subscription-popup.page.html',
  styleUrls: ['./subscription-popup.page.scss']
})
export class SubscriptionPopupPage implements OnInit {

  title: string = 'Subscribe DocShowApp';

  constructor(private modalController: ModalController) { }

  ngOnInit(): void {
  }

  closeModal(action = null) {
    if (action)
      this.modalController.dismiss({ action });
    else
      this.modalController.dismiss();
  }

}
