import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';;
import { environment } from 'src/environments/environment';
import { HELPERS } from '../_helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {
  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  public createSubscriptionPlan = payload => {
    let apiEndPoint = '/subscriptionplan/create';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getAllSubscriptionPlan = () => {
    let apiEndPoint = '/subscriptionplan/getall';
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
  public getAllSubscriptionPlanDetail = () => {
    let apiEndPoint = '/subscription/getByUserId';
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getSubscriptionPlanById = id => {
    let apiEndPoint = `/subscriptionplan/getById?id=${id}`;
    return this.http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public paySubscriptionPlan = payload => {
    let apiEndPoint = '/subscription/create';
    return this.http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }
}
