import { MapsAPILoader } from '@agm/core';
import { Route } from '@angular/compiler/src/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { Plugins } from '@capacitor/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './open-reminder.page.html',
  styleUrls: ['./open-reminder.page.scss']
})
export class OpenReminderPage implements OnInit {


  @Input() fromParent;
  @Input() Title;
  @Input() Message;
  @Input() Id;
  
  
  //public searchElementRef: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, private ngZone: NgZone, public modalController: ModalController) { }

  ngOnInit() {
    
    this.route.params.subscribe(params => {
      
      if (Object.keys(params).length > 0) {
        this.Title = params['title'];
        this.Message = params['message'];
        this.Id = params['notification_type_id'];
      }
      
    });
     this.playSound();
    // Plugins.SoundEffect.loadSound({ id: 'aler-sound', path: `sounds/aler-sound.mp3` })

    // Plugins.SoundEffect.play({ id: 'aler-sound' })
  }

  playSound(){
    const  audio = new Audio();
    audio.src = "../../../assets/sounds/aler-sound.mp3";
     audio.load();
     audio.play();
  }

  closeModal() {
    this.modalController.dismiss();
    this.router.navigate(['/patient/reminder-list']);
  }

  // openReminder() {
  //   this.modalController.dismiss();
  //   this.router.navigate(['/patient/reminder-list']);
  // }

  openReminder(Id) {
    //this.router.navigate(['/patient/edit-reminder', id]);
    
    let id = Id;
    this.modalController.dismiss();
    this.router.navigate(['/patient/reminder-detail', id]);
  }
}
