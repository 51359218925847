/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MySubscriptionPage } from './pages/patient/subscription/my-subscription/my-subscription.page';
import { PaySubscriptionPage } from './pages/patient/subscription/pay-subscription/pay-subscription.page';
import { SubscriptionHistoryPage } from './pages/patient/subscription/subscription-history/subscription-history.page';
import { SubscriptionInfoPage } from './pages/patient/subscription/subscription-info/subscription-info.page';
import { PatientLayoutPage } from './pages/shared/patient-layout/patient-layout.page';
import { ProviderLayoutPage } from './pages/shared/provider-layout/provider-layout.page';
import { PhysicianLayoutPage } from './pages/shared/physician-layout/physician-layout.page';
import { SiteAdminLayoutPage } from './pages/shared/site-admin-layout/site-admin-layout.page';
import { AuthGuard } from './_helpers/auth.guard';
import { RedirectGuard } from './_helpers/redirect.guard';
import { ConfirmEmailPage } from './pages/common/confirm-email/confirm-email.page';
import { PhysicianConfirmEmailPage } from './pages/common/physician-confirm-email/physician-confirm-email.page';
import { PrivacyPolicyComponent } from './pages/shared/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './pages/shared/components/terms-and-conditions/terms-and-conditions.component';
import { FaqPage } from './pages/shared/components/faq-page/faq-page.component';
import { OpenReminderPage } from './pages/patient/notifications/open-reminder/open-reminder.page';
import { SupportPage } from './pages/shared/components/support/support.component';
import { OpenDescriptionPage } from './pages/provider/documents/open-description/open-description.page';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full', canActivate: [RedirectGuard] },
  {
    path: 'patient',
    component: PatientLayoutPage,
    children: [
      { path: '', loadChildren: () => import('./pages/patient/tabs/tabs.module').then(m => m.PatientTabsModule) },
      { path: 'change-password', loadChildren: () => import('./pages/common/change-password/change-password.module').then(m => m.ChangePasswordModule) },
      { path: 'edit-profile', loadChildren: () => import('./pages/patient/profile-edit/profile-edit.module').then(m => m.PatientProfileEditModule) },
      { path: 'credit-card', loadChildren: () => import('./pages/patient/credit-card/credit-card.module').then(m => m.PatientCreditCardModule) },
      { path: 'medication', loadChildren: () => import('./pages/patient/patient-medication/patient-medication.module').then(m => m.PatientMedicationModule) },
      { path: 'medical-history', loadChildren: () => import('./pages/patient/documents/documents.module').then(m => m.PatientDocumentsModule) },
      { path: 'my-providers', loadChildren: () => import('./pages/patient/my-providers/my-providers.module').then(m => m.MyProvidersModule) },
      { path: 'provider-list', loadChildren: () => import('./pages/patient/select-provider/select-provider.module').then(m => m.SelectProviderModule) },
      { path: 'book-appointment', loadChildren: () => import('./pages/patient/book-appointment/book-appointment.module').then(m => m.BookAppointmentModule) },
      { path: 'appointment-detail/:id', loadChildren: () => import('./pages/patient/appointment-detail/appointment-detail.module').then(m => m.AppointmentDetailModule) },
      { path: 'booking-confirmation', loadChildren: () => import('./pages/patient/booking-confirmation/booking-confirmation.module').then(m => m.BookingConfirmationModule) },
      { path: 'reward-history', loadChildren: () => import('./pages/patient/reward-history/reward-history.module').then(m => m.RewardHistoryModule) },
      { path: 'patient-appointment-rewards/:id', loadChildren: () => import('./pages/patient/appointment-rewards/appointment-rewards.module').then(m => m.AppointmentRewardsModule) },
      { path: 'my-membership', loadChildren: () => import('./pages/patient/membership/membership.module').then(m => m.MembershipModule) },
      { path: 'my-subscription', component: MySubscriptionPage },
      { path: 'pay-subscription', component: PaySubscriptionPage },
      { path: 'subscription-history', component: SubscriptionHistoryPage },
      { path: 'subscription-info', component: SubscriptionInfoPage },
      { path: 'transaction-log', loadChildren: () => import('./pages/patient/transaction-log/transaction-log.module').then(m => m.PatientTransactionLogModule) },
      { path: 'my-reward-history', loadChildren: () => import('./pages/patient/patient-reward-history/patient-reward-history.module').then(m => m.PatientRewardHistoryModule) },
      { path: 'reward-history-detail', loadChildren: () => import('./pages/patient/patient-reward-history/reward-history-detail/reward-history-detail.module').then(m => m.RewardHistoryDetailModule) },
      { path: 'my-offers', loadChildren: () => import('./pages/patient/my-offers/my-offers.module').then(m => m.MyOffersPageModule) },
      { path: 'offer-details/:id/:planId', loadChildren: () => import('./pages/patient/my-offers/offer-details/offer-details.module').then(m => m.OfferDetailPageModule) },
      { path: 'offer-view', loadChildren: () => import('./pages/patient/my-offers/offer-view/offer-view.module').then(m => m.OfferViewPageModule) },
      { path: 'my-cart/:offer_key/:redemption_method', loadChildren: () => import('./pages/patient/coupons/my-cart.module').then(m => m.MyCartPageModule) },
      { path: 'coupon-purchase-success/:promotion_code/:offer_key', loadChildren: () => import('./pages/patient/coupons/coupon-purchase-success/coupon-purchase-success.module').then(m => m.CouponPurchaseSuccessPageModule) },
      { path: 'coupon-purchase-success/:offer_key', loadChildren: () => import('./pages/patient/coupons/coupon-purchase-success/coupon-purchase-success.module').then(m => m.CouponPurchaseSuccessPageModule) },
      { path: 'feedback/:id', loadChildren: () => import('./pages/patient/feedback/feedback.module').then(m => m.FeedbackPageModule) },
      { path: 'notification-list', loadChildren: () => import('./pages/patient/notifications/notification-list.module').then(m => m.NotificationListModule) },
      { path: 'add-reminder', loadChildren: () => import('./pages/patient/notifications/add-reminder/add-reminder.module').then(m => m.AddReminderModule) },
      { path: 'notification-setting', loadChildren: () => import('./pages/patient/notifications/notification-setting/notification-setting.module').then(m => m.NotificationSettingModule) },
      { path: 'edit-reminder/:id', loadChildren: () => import('./pages/patient/notifications/edit-reminder/edit-reminder.module').then(m => m.EditReminderModule) },
      { path: 'set-recurrance', loadChildren: () => import('./pages/patient/notifications/set-recurrance/set-recurrance.module').then(m => m.SetRecurranceModule) },
      { path: 'reminder-list', loadChildren: () => import('./pages/patient/notifications/reminder-list/reminder-list.module').then(m => m.ReminderListModule) },
      { path: 'edit-recurrance/:id', loadChildren: () => import('./pages/patient/notifications/edit-recurrance/edit-recurrance.module').then(m => m.EditRecurranceModule) },
      { path: 'reminder-detail/:id', loadChildren: () => import('./pages/patient/notifications/reminder-detail/reminder-detail.module').then(m => m.ReminderDetailModule) },
      { path: 'medication', loadChildren: () => import('./pages/patient/patient-medication/patient-medication.module').then(m => m.PatientMedicationModule) },
      { path: 'add-medication', loadChildren: () => import('./pages/patient/patient-medication/add-patient-medication/add-patient-medication.module').then(m => m.AddPatientMedicationModule) },
      { path: 'edit-medication/:id', loadChildren: () => import('./pages/patient/patient-medication/add-patient-medication/add-patient-medication.module').then(m => m.AddPatientMedicationModule) },
      { path: 'add-allergy', loadChildren: () => import('./pages/patient/patient-medication/add-patient-allergy/add-patient-allergy.module').then(m => m.AddPatientAllergyModule) },
      { path: 'edit-allergy/:id', loadChildren: () => import('./pages/patient/patient-medication/add-patient-allergy/add-patient-allergy.module').then(m => m.AddPatientAllergyModule) },
    ],
    canActivate: [AuthGuard], data: { userType: 1 }
  },
  {
    path: 'provider',
    component: ProviderLayoutPage,
    children: [
      { path: '', loadChildren: () => import('./pages/provider/tabs/tabs.module').then(m => m.ProviderTabsModule) },
      { path: 'change-password', loadChildren: () => import('./pages/common/change-password/change-password.module').then(m => m.ChangePasswordModule) },
      { path: 'edit-profile', loadChildren: () => import('./pages/provider/profile-edit/profile-edit.module').then(m => m.ProviderProfileEditModule) },
      { path: 'availability-time', loadChildren: () => import('./pages/provider/availability-time/availability-time.module').then(m => m.AvailabilityTimeModule) },
      { path: 'availability-schedule', loadChildren: () => import('./pages/provider/availability-schedule/availability-schedule.module').then(m => m.AvailabilityScheduleModule) },
      { path: 'approved-appointments', loadChildren: () => import('./pages/provider/appointments/appointments.module').then(m => m.ProviderAppointmentsModule), data: { module: 'approved-app' } },
      { path: 'todays-appointments', loadChildren: () => import('./pages/provider/appointments/appointments.module').then(m => m.ProviderAppointmentsModule), data: { module: 'todays-app' } },
      { path: 'appointment-detail/:id', loadChildren: () => import('./pages/provider/appointment-detail/appointment-detail.module').then(m => m.AppointmentDetailModule) },
      { path: 'all-appointments', loadChildren: () => import('./pages/provider/all-appointments/all-appointments.module').then(m => m.ProviderAllAppointmentsModule), data: { module: 'approved-app' } },
      { path: 'all-todays-appointments', loadChildren: () => import('./pages/provider/all-appointments/all-appointments.module').then(m => m.ProviderAllAppointmentsModule), data: { module: 'todays-app' } },
      { path: 'all-appointment-detail/:id', loadChildren: () => import('./pages/provider/all-appointment-detail/all-appointment-detail.module').then(m => m.AllAppointmentDetailModule) },
      { path: 'facility', loadChildren: () => import('./pages/provider/facility/facility.module').then(m => m.ProviderFacilityModule) },      
      // { path: 'edit-facility', loadChildren: () => import('./pages/provider/facility-edit/facility-edit.module').then(m => m.ProviderFacilityEditModule) },
      { path: 'locations-list', loadChildren: () => import('./pages/provider/locations/locations-list.module').then(m => m.ProviderLocationsListModule) },
      // { path: 'add-locations', loadChildren: () => import('./pages/provider/locations/add-locations/add-locations.module').then(m => m.AddLocationsModule) },
      { path: 'locations-edit/:id', loadChildren: () => import('./pages/provider/locations/edit-locations/edit-locations.module').then(m => m.EditLocationsModule) },
      { path: 'invitation-list', loadChildren: () => import('./pages/provider/invitation-list/invitation-list.module').then(m => m.InvitationListModule) },
      // { path: 'manage-site-admins', loadChildren: () => import('./pages/provider/manage-site-admins/manage-site-admins.module').then(m => m.ManageSiteAdminsModule) },
      // { path: 'add-admin', loadChildren: () => import('./pages/provider/manage-site-admins/add-admin/add-admin.module').then(m => m.AddAdminModule) },
      // { path: 'locations-edit/:id', loadChildren: () => import('./pages/provider/manage-site-admins/edit-admin/edit-admin.module').then(m => m.EditAdminModule) },
      { path: 'facility-providers', loadChildren: () => import('./pages/provider/facility-providers/facility-providers.module').then(m => m.FacilityProvidersModule) },
      // { path: 'add-doctors', loadChildren: () => import('./pages/provider/facility-providers/add-doctors/add-doctors.module').then(m => m.AddDoctorsModule) },     
      // { path: 'edit-doctors', loadChildren: () => import('./pages/provider/facility-providers/edit-doctors/edit-doctors.module').then(m => m.EditDoctorsModule) },     
      { path: 'search-providers', loadChildren: () => import('./pages/provider/facility-search-providers/facility-search-providers.module').then(m => m.FacilitySearchProvidersModule) },
      // { path: 'invite-providers', loadChildren: () => import('./pages/provider/facility-invite-providers/facility-invite-providers.module').then(m => m.FacilityInviteProvidersModule) },
      { path: 'my-patients', loadChildren: () => import('./pages/provider/patients/my-patients/my-patients.module').then(m => m.MyPatientsModule) },
      { path: 'all-patients', loadChildren: () => import('./pages/provider/patients/all-patients/all-patients.module').then(m => m.AllPatientsModule) },
      { path: 'patient-details/:id', loadChildren: () => import('./pages/provider/patients/patient-details/patient-details.module').then(m => m.PatientDetailsModule) },
      { path: 'patient-appointments/:id', loadChildren: () => import('./pages/provider/patient-appointments/patient-appointments.module').then(m => m.ProviderPatientAppointmentsModule) },
      { path: 'medical-history/:id', loadChildren: () => import('./pages/provider/patients/patient-documents/patient-documents.module').then(m => m.PatientDocumentsModule) },
      { path: 'patient-document', loadChildren: () => import('./pages/provider/patients/patient-document-details/patient-document-details.module').then(m => m.PatientDocumentDetailsModule) },
      { path: 'reward-criteria', loadChildren: () => import('./pages/provider/reward-criteria/reward-criteria.module').then(m => m.ProviderRewardCriteriaModule) },
      { path: 'reward-criteria/add', loadChildren: () => import('./pages/provider/reward-criteria/add-reward-criteria/add-reward-criteria.module').then(m => m.AddRewardCriteriaModule) },
      { path: 'reward-criteria/edit/:id', loadChildren: () => import('./pages/provider/reward-criteria/edit-reward-criteria/edit-reward-criteria.module').then(m => m.EditRewardCriteriaModule) },
      { path: 'patient-rewards/:id', loadChildren: () => import('./pages/provider/reward-points/patient-rewards/patient-rewards.module').then(m => m.PatientRewardsModule) },
      { path: 'add-reward-point/:id', loadChildren: () => import('./pages/provider/reward-points/add-reward-point/add-reward-point.module').then(m => m.AddRewardPointModule) },
      { path: 'my-reward-history', loadChildren: () => import('./pages/provider/reward-points/reward-history/reward-history.module').then(m => m.RewardHistoryModule) },
      { path: 'my-transactions', loadChildren: () => import('./pages/provider/transactions/transactions.module').then(m => m.ProviderTransactionsModule) },
      { path: 'reminder-list', loadChildren: () => import('./pages/provider/Reminders/reminder-list/reminderlist.module').then(m => m.ReminderListModule) },
      { path: 'add-reminder', loadChildren: () => import('./pages/provider/Reminders/reminder-list/add-reminder/add-reminder.module').then(m => m.AddReminderModule) },
      { path: 'edit-reminder/:id', loadChildren: () => import('./pages/provider/Reminders/reminder-list/edit-reminder/edit-reminder.module').then(m => m.EditReminderModule) },
      { path: 'set-recurrance', loadChildren: () => import('./pages/provider/Reminders/reminder-list/set-recurrance/set-recurrance.module').then(m => m.SetRecurranceModule) },
      { path: 'notification-list', loadChildren: () => import('./pages/provider/Reminders/reminder-list/notifications/notificationlist.module').then(m => m.NotificationListModule) },
      { path: 'edit-recurrance/:id', loadChildren: () => import('./pages/provider/Reminders/reminder-list/edit-recurrance/edit-recurrance.module').then(m => m.EditRecurranceModule) },
      { path: 'notification-setting', loadChildren: () => import('./pages/provider/Reminders/reminder-list/notification-setting/notification-setting.module').then(m => m.NotificationSettingModule) },
      { path: 'open-description', component: OpenDescriptionPage },
      { path: 'reminder-detail/:id', loadChildren: () => import('./pages/patient/notifications/reminder-detail/reminder-detail.module').then(m => m.ReminderDetailModule) },
      { path: 'health-chart/:id', loadChildren: () => import('./pages/provider/patients/patient-health-chart/patient-health-chart.module').then(m => m.PatientHealthChartModule) },

    ],
    canActivate: [AuthGuard], 
       data: { userType: 2 }
    },

  {
    path: 'physician',
    component: PhysicianLayoutPage,
    children: [
      { path: '', loadChildren: () => import('./pages/physician/tabs/tabs.module').then(m => m.PhysicianTabsModule) },
      { path: 'change-password', loadChildren: () => import('./pages/common/change-password/change-password.module').then(m => m.ChangePasswordModule) },
      { path: 'edit-profile', loadChildren: () => import('./pages/physician/profile-edit/profile-edit.module').then(m => m.ProviderProfileEditModule) },
      { path: 'availability-time', loadChildren: () => import('./pages/physician/availability-time/availability-time.module').then(m => m.AvailabilityTimeModule) },      
      { path: 'availability-time/:id', loadChildren: () => import('./pages/physician/availability-time/availability-time.module').then(m => m.AvailabilityTimeModule) },

      { path: 'availability-schedule', loadChildren: () => import('./pages/physician/availability-schedule/availability-schedule.module').then(m => m.AvailabilityScheduleModule) },
      
      { path: 'availability-schedule/:id', loadChildren: () => import('./pages/physician/availability-schedule/availability-schedule.module').then(m => m.AvailabilityScheduleModule) },

      { path: 'appointments', loadChildren: () => import('./pages/physician/appointments/appointments.module').then(m => m.PhysicianAppointmentsModule), data: { module: 'all-app' } },
      { path: 'approved-appointments', loadChildren: () => import('./pages/physician/appointments/appointments.module').then(m => m.PhysicianAppointmentsModule), data: { module: 'approved-app' } },
      { path: 'todays-appointments', loadChildren: () => import('./pages/physician/appointments/appointments.module').then(m => m.PhysicianAppointmentsModule), data: { module: 'todays-app' } },
      { path: 'appointment-detail/:id', loadChildren: () => import('./pages/physician/appointment-detail/appointment-detail.module').then(m => m.AppointmentDetailModule) },
      { path: 'all-appointments', loadChildren: () => import('./pages/physician/all-appointments/all-appointments.module').then(m => m.PhysicianAllAppointmentsModule), data: { module: 'all-app' } },
      { path: 'all-approved-appointments', loadChildren: () => import('./pages/physician/all-appointments/all-appointments.module').then(m => m.PhysicianAllAppointmentsModule), data: { module: 'approved-app' } },
      { path: 'all-todays-appointments', loadChildren: () => import('./pages/physician/all-appointments/all-appointments.module').then(m => m.PhysicianAllAppointmentsModule), data: { module: 'todays-app' } },
      { path: 'all-appointment-detail/:id', loadChildren: () => import('./pages/physician/all-appointment-detail/all-appointment-detail.module').then(m => m.AllAppointmentDetailModule) },
      { path: 'facility', loadChildren: () => import('./pages/physician/facility/facility.module').then(m => m.PhysicianFacilityModule) },      
      { path: 'edit-facility', loadChildren: () => import('./pages/physician/facility-edit/facility-edit.module').then(m => m.PhysicianFacilityEditModule) },
      { path: 'doctor-list/:id', loadChildren: () => import('./pages/physician/facility-doctors-list/facility-doctors-list.module').then(m => m.FacilityDoctorsListModule) },
      { path: 'locations-list', loadChildren: () => import('./pages/physician/locations/locations-list.module').then(m => m.PhysicianLocationsListModule) },
      { path: 'location/:id', loadChildren: () => import('./pages/physician/location/location.module').then(m => m.LocationModule) },
      { path: 'add-locations', loadChildren: () => import('./pages/physician/locations/add-locations/add-locations.module').then(m => m.AddLocationsModule) },
      { path: 'locations-edit/:id', loadChildren: () => import('./pages/physician/locations/edit-locations/edit-locations.module').then(m => m.EditLocationsModule) },
      { path: 'invite-doctors', loadChildren: () => import('./pages/physician/invite-doctors/invite-doctors.module').then(m => m.InviteDoctorsModule) },
      { path: 'invitation-list', loadChildren: () => import('./pages/physician/invitation-list/invitation-list.module').then(m => m.InvitationListModule) },
      { path: 'manage-site-admins', loadChildren: () => import('./pages/physician/manage-site-admins/manage-site-admins.module').then(m => m.ManageSiteAdminsModule) },
      { path: 'add-admin', loadChildren: () => import('./pages/physician/manage-site-admins/add-admin/add-admin.module').then(m => m.AddAdminModule) },
      { path: 'edit-admin/:id', loadChildren: () => import('./pages/physician/manage-site-admins/edit-admin/edit-admin.module').then(m => m.EditAdminModule) },
      { path: 'facility-providers', loadChildren: () => import('./pages/physician/facility-providers/facility-providers.module').then(m => m.FacilityProvidersModule) },
      { path: 'add-doctors', loadChildren: () => import('./pages/physician/facility-providers/add-doctors/add-doctors.module').then(m => m.AddDoctorsModule) },     
      { path: 'edit-doctors', loadChildren: () => import('./pages/physician/facility-providers/edit-doctors/edit-doctors.module').then(m => m.EditDoctorsModule) },     
      { path: 'faciliyt-search-providers', loadChildren: () => import('./pages/physician/facility-search-providers/facility-search-providers.module').then(m => m.FacilitySearchProvidersModule) },
      { path: 'faciliyt-invite-providers', loadChildren: () => import('./pages/physician/facility-invite-providers/facility-invite-providers.module').then(m => m.FacilityInviteProvidersModule) },
      { path: 'my-patients', loadChildren: () => import('./pages/physician/patients/my-patients/my-patients.module').then(m => m.MyPatientsModule) },
      { path: 'all-patients', loadChildren: () => import('./pages/physician/patients/all-patients/all-patients.module').then(m => m.AllPatientsModule) },
      { path: 'patient-details/:id', loadChildren: () => import('./pages/physician/patients/patient-details/patient-details.module').then(m => m.PatientDetailsModule) },
      { path: 'patient-appointments/:id', loadChildren: () => import('./pages/physician/patient-appointments/patient-appointments.module').then(m => m.PhysicianPatientAppointmentsModule) },
      { path: 'medical-history/:id', loadChildren: () => import('./pages/physician/patients/patient-documents/patient-documents.module').then(m => m.PatientDocumentsModule) },
      { path: 'patient-document', loadChildren: () => import('./pages/physician/patients/patient-document-details/patient-document-details.module').then(m => m.PatientDocumentDetailsModule) },
      { path: 'reward-criteria', loadChildren: () => import('./pages/physician/reward-criteria/reward-criteria.module').then(m => m.PhysicianRewardCriteriaModule) },
      { path: 'reward-criteria/add', loadChildren: () => import('./pages/physician/reward-criteria/add-reward-criteria/add-reward-criteria.module').then(m => m.AddRewardCriteriaModule) },
      { path: 'reward-criteria/edit/:id', loadChildren: () => import('./pages/physician/reward-criteria/edit-reward-criteria/edit-reward-criteria.module').then(m => m.EditRewardCriteriaModule) },
      { path: 'patient-rewards/:id', loadChildren: () => import('./pages/physician/reward-points/patient-rewards/patient-rewards.module').then(m => m.PatientRewardsModule) },
      { path: 'add-reward-point/:id', loadChildren: () => import('./pages/physician/reward-points/add-reward-point/add-reward-point.module').then(m => m.AddRewardPointModule) },
      { path: 'my-reward-history', loadChildren: () => import('./pages/physician/reward-points/reward-history/reward-history.module').then(m => m.RewardHistoryModule) },
      { path: 'my-transactions', loadChildren: () => import('./pages/physician/transactions/transactions.module').then(m => m.PhysicianTransactionsModule) },
      { path: 'reminder-list', loadChildren: () => import('./pages/physician/Reminders/reminder-list/reminderlist.module').then(m => m.ReminderListModule) },
      { path: 'add-reminder', loadChildren: () => import('./pages/physician/Reminders/reminder-list/add-reminder/add-reminder.module').then(m => m.AddReminderModule) },
      { path: 'edit-reminder/:id', loadChildren: () => import('./pages/physician/Reminders/reminder-list/edit-reminder/edit-reminder.module').then(m => m.EditReminderModule) },
      { path: 'set-recurrance', loadChildren: () => import('./pages/physician/Reminders/reminder-list/set-recurrance/set-recurrance.module').then(m => m.SetRecurranceModule) },
      { path: 'notification-list', loadChildren: () => import('./pages/physician/Reminders/reminder-list/notifications/notificationlist.module').then(m => m.NotificationListModule) },
      { path: 'edit-recurrance/:id', loadChildren: () => import('./pages/physician/Reminders/reminder-list/edit-recurrance/edit-recurrance.module').then(m => m.EditRecurranceModule) },
      { path: 'notification-setting', loadChildren: () => import('./pages/physician/Reminders/reminder-list/notification-setting/notification-setting.module').then(m => m.NotificationSettingModule) },
      { path: 'open-description', component: OpenDescriptionPage },
      { path: 'reminder-detail/:id', loadChildren: () => import('./pages/patient/notifications/reminder-detail/reminder-detail.module').then(m => m.ReminderDetailModule) },
      { path: 'health-chart/:id', loadChildren: () => import('./pages/physician/patients/patient-health-chart/patient-health-chart.module').then(m => m.PatientHealthChartModule) },

    ],
    canActivate: [AuthGuard], 
        data: { userType: 4 }
  },
  {
    path: 'site-admin',
    component: SiteAdminLayoutPage,
    children: [
      { path: '', loadChildren: () => import('./pages/site-admin/tabs/tabs.module').then(m => m.SiteAdminTabsModule) },
      { path: 'change-password', loadChildren: () => import('./pages/common/change-password/change-password.module').then(m => m.ChangePasswordModule) },
      { path: 'edit-profile', loadChildren: () => import('./pages/site-admin/profile-edit/profile-edit.module').then(m => m.SiteAdminProfileEditModule) },
      { path: 'availability-time', loadChildren: () => import('./pages/site-admin/availability-time/availability-time.module').then(m => m.AvailabilityTimeModule) },
      { path: 'availability-time/:id', loadChildren: () => import('./pages/site-admin/availability-time/availability-time.module').then(m => m.AvailabilityTimeModule) },

      { path: 'availability-schedule', loadChildren: () => import('./pages/site-admin/availability-schedule/availability-schedule.module').then(m => m.AvailabilityScheduleModule) },
      { path: 'approved-appointments', loadChildren: () => import('./pages/site-admin/appointments/appointments.module').then(m => m.SiteAdminAppointmentsModule), data: { module: 'approved-app' } },
      { path: 'todays-appointments', loadChildren: () => import('./pages/site-admin/appointments/appointments.module').then(m => m.SiteAdminAppointmentsModule), data: { module: 'todays-app' } },
      { path: 'appointment-detail/:id', loadChildren: () => import('./pages/site-admin/appointment-detail/appointment-detail.module').then(m => m.AppointmentDetailModule) },
      { path: 'all-appointments', loadChildren: () => import('./pages/site-admin/all-appointments/all-appointments.module').then(m => m.SiteAdminAllAppointmentsModule), data: { module: 'approved-app' } },
      { path: 'all-todays-appointments', loadChildren: () => import('./pages/site-admin/all-appointments/all-appointments.module').then(m => m.SiteAdminAllAppointmentsModule), data: { module: 'todays-app' } },
      { path: 'all-appointment-detail/:id', loadChildren: () => import('./pages/site-admin/all-appointment-detail/all-appointment-detail.module').then(m => m.AllAppointmentDetailModule) },
      { path: 'facility', loadChildren: () => import('./pages/site-admin/facility/facility.module').then(m => m.SiteAdminFacilityModule) },      
      { path: 'edit-facility', loadChildren: () => import('./pages/site-admin/facility-edit/facility-edit.module').then(m => m.SiteAdminFacilityEditModule) },
      { path: 'doctor-list/:id', loadChildren: () => import('./pages/site-admin/facility-doctors-list/facility-doctors-list.module').then(m => m.FacilityDoctorsListModule) },
      { path: 'locations-list', loadChildren: () => import('./pages/site-admin/locations/locations-list.module').then(m => m.SiteAdminLocationsListModule) },
      { path: 'add-locations', loadChildren: () => import('./pages/site-admin/locations/add-locations/add-locations.module').then(m => m.AddLocationsModule) },
      { path: 'locations-edit/:id', loadChildren: () => import('./pages/site-admin/locations/edit-locations/edit-locations.module').then(m => m.EditLocationsModule) },

      { path: 'location/:id', loadChildren: () => import('./pages/site-admin/location/location.module').then(m => m.LocationModule) },
      { path: 'facility-provider-doctor/:id', loadChildren: () => import('./pages/site-admin/facility-provider-doctor/facility-provider.module').then(m => m.FacilityProviderModule) },
      { path: 'invitation-list', loadChildren: () => import('./pages/site-admin/invitation-list/invitation-list.module').then(m => m.InvitationListModule) },
      { path: 'invite-doctors', loadChildren: () => import('./pages/site-admin/invite-doctors/invite-doctors.module').then(m => m.InviteDoctorsModule) },
      { path: 'manage-site-admins', loadChildren: () => import('./pages/site-admin/manage-site-admins/manage-site-admins.module').then(m => m.ManageSiteAdminsModule) },
      { path: 'add-admin', loadChildren: () => import('./pages/site-admin/manage-site-admins/add-admin/add-admin.module').then(m => m.AddAdminModule) },
      { path: 'edit-admin/:id', loadChildren: () => import('./pages/site-admin/manage-site-admins/edit-admin/edit-admin.module').then(m => m.EditAdminModule) },
      { path: 'facility-providers', loadChildren: () => import('./pages/site-admin/facility-providers/facility-providers.module').then(m => m.FacilityProvidersModule) },
      { path: 'add-doctors', loadChildren: () => import('./pages/site-admin/facility-providers/add-doctors/add-doctors.module').then(m => m.AddDoctorsModule) },     
      { path: 'edit-doctors/:id', loadChildren: () => import('./pages/site-admin/facility-providers/edit-doctors/edit-doctors.module').then(m => m.EditDoctorsModule) },     
      { path: 'search-site-admins', loadChildren: () => import('./pages/site-admin/facility-search-providers/facility-search-providers.module').then(m => m.FacilitySearchProvidersModule) },
      { path: 'invite-site-admins', loadChildren: () => import('./pages/site-admin/facility-invite-providers/facility-invite-providers.module').then(m => m.FacilityInviteProvidersModule) },
      { path: 'my-patients', loadChildren: () => import('./pages/site-admin/patients/my-patients/my-patients.module').then(m => m.MyPatientsModule) },
      { path: 'all-patients', loadChildren: () => import('./pages/site-admin/patients/all-patients/all-patients.module').then(m => m.AllPatientsModule) },
      { path: 'patient-details/:id', loadChildren: () => import('./pages/site-admin/patients/patient-details/patient-details.module').then(m => m.PatientDetailsModule) },
      { path: 'patient-appointments/:id', loadChildren: () => import('./pages/site-admin/patient-appointments/patient-appointments.module').then(m => m.SiteAdminPatientAppointmentsModule) },
      { path: 'medical-history/:id', loadChildren: () => import('./pages/site-admin/patients/patient-documents/patient-documents.module').then(m => m.PatientDocumentsModule) },
      { path: 'patient-document', loadChildren: () => import('./pages/site-admin/patients/patient-document-details/patient-document-details.module').then(m => m.PatientDocumentDetailsModule) },
      { path: 'reward-criteria', loadChildren: () => import('./pages/site-admin/reward-criteria/reward-criteria.module').then(m => m.SiteAdminRewardCriteriaModule) },
      { path: 'reward-criteria/add', loadChildren: () => import('./pages/site-admin/reward-criteria/add-reward-criteria/add-reward-criteria.module').then(m => m.AddRewardCriteriaModule) },
      { path: 'reward-criteria/edit/:id', loadChildren: () => import('./pages/site-admin/reward-criteria/edit-reward-criteria/edit-reward-criteria.module').then(m => m.EditRewardCriteriaModule) },
      { path: 'patient-rewards/:id', loadChildren: () => import('./pages/site-admin/reward-points/patient-rewards/patient-rewards.module').then(m => m.PatientRewardsModule) },
      { path: 'add-reward-point/:id', loadChildren: () => import('./pages/site-admin/reward-points/add-reward-point/add-reward-point.module').then(m => m.AddRewardPointModule) },
      { path: 'my-reward-history', loadChildren: () => import('./pages/site-admin/reward-points/reward-history/reward-history.module').then(m => m.RewardHistoryModule) },
      { path: 'my-transactions', loadChildren: () => import('./pages/site-admin/transactions/transactions.module').then(m => m.SiteAdminTransactionsModule) },
      { path: 'reminder-list', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/reminderlist.module').then(m => m.ReminderListModule) },
      { path: 'add-reminder', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/add-reminder/add-reminder.module').then(m => m.AddReminderModule) },
      { path: 'edit-reminder/:id', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/edit-reminder/edit-reminder.module').then(m => m.EditReminderModule) },
      { path: 'set-recurrance', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/set-recurrance/set-recurrance.module').then(m => m.SetRecurranceModule) },
      { path: 'notification-list', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/notifications/notificationlist.module').then(m => m.NotificationListModule) },
      { path: 'edit-recurrance/:id', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/edit-recurrance/edit-recurrance.module').then(m => m.EditRecurranceModule) },
      { path: 'notification-setting', loadChildren: () => import('./pages/site-admin/Reminders/reminder-list/notification-setting/notification-setting.module').then(m => m.NotificationSettingModule) },
      { path: 'open-description', component: OpenDescriptionPage },
      { path: 'reminder-detail/:id', loadChildren: () => import('./pages/patient/notifications/reminder-detail/reminder-detail.module').then(m => m.ReminderDetailModule) },
      { path: 'health-chart/:id', loadChildren: () => import('./pages/site-admin/patients/patient-health-chart/patient-health-chart.module').then(m => m.PatientHealthChartModule) },

    ],
    canActivate: [AuthGuard], 
        data: { userType: 5,userSuperAdmin:6 }
  },
  
  { path: 'login', loadChildren: () => import('./pages/common/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/common/register/register.module').then(m => m.RegisterModule) },
  { path: 'register-doctor', loadChildren: () => import('./pages/common/register-doctor/register-doctor.module').then(m => m.RegisterDoctorModule) },
  { path: 'confirm-email', loadChildren: () => import('./pages/common/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule)},
  { path: 'physician-confirm-email', loadChildren: () => import('./pages/common/physician-confirm-email/physician-confirm-email.module').then(m => m.PhysicianConfirmEmailModule)},
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'faq', component: FaqPage },
  { path: 'support', component: SupportPage },
  { path: 'open-reminder', component: OpenReminderPage },
  { path: 'terms-conditions', component: TermsAndConditionsComponent },
  { path: 'forgot-password', loadChildren: () => import('./pages/common/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'reset-password', loadChildren: () => import('./pages/common/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

