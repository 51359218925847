import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  private _baseUrl = environment.API_URL;
  private _headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private _http: HttpClient) { }

  // Get profile api
  public getEligibleRewardCriteria = payload => {
    let apiEndPoint = `/rewards/geteligiblecriteria${payload}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public addPatientRewards = payload => {
    let apiEndPoint = '/rewards/create';
    return this._http.post<any>(this._baseUrl + apiEndPoint, payload, this._headers);
  }

  public getProviderRewards(providerId) {
    let apiEndPoint = `/rewards/getproviderrewards?providerId=${providerId}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getPatientRewards(params) {
    let apiEndPoint = `/rewards/getpatientrewards?patientId=${params.userId}&page=${params.page}&type=${params.type}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getAllPatientReward() {
    let apiEndPoint = '/rewards/getallpatientrewards';
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }

  public getTotalRewardPoint(id) {
    let apiEndPoint = `/rewards/gettotalrewardpoint?patientId=${id}`;
    return this._http.get<any>(this._baseUrl + apiEndPoint, this._headers);
  }
}
